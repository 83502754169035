<template>
  <div class="code-matching">
    <div class="code-matching-title">
      河北商保连锁商品列表
      <div class="back-account-center">
        <router-link to="/chain/goodsList">
          <el-button type="link" icon="el-icon-arrow-left">返回</el-button>
        </router-link>
      </div>
    </div>
    <div class="code-matching-content">
      <div class="code-matching-main">
        <div class="search-content">
          <div class="wrapper">
            <el-form @keyup.enter.native="search">
              <div class="left">
                <el-input
                  class="search-input"
                  clearable
                  v-model="searchForm.searchVal"
                  placeholder="搜索商品名称"
                ></el-input>
                <el-input
                  class="search-manufacturer"
                  clearable
                  v-model="searchForm.manufacturer"
                  placeholder="搜索商品生产商家"
                ></el-input>
                <el-input
                  class="search-input"
                  clearable
                  v-model="searchForm.format"
                  placeholder="搜索商品规格"
                ></el-input>
                <el-input
                  class="search-input"
                  clearable
                  v-model="searchForm.license"
                  placeholder="搜索商品批准文号"
                ></el-input>
                <!-- <el-tooltip
                effect="dark"
                content="请选择商品关联匹配状态"
                placement="top"
              >
                <el-select
                  v-model="searchForm.mapFlag"
                  placeholder="请选择匹配状态"
                >
                  <el-option
                    v-for="item in mapFlagOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option> </el-select
              ></el-tooltip> -->
                <el-tooltip
                v-if="false"
                  effect="dark"
                  content="请选择商品关联匹配方式"
                  placement="top"
                >
                  <el-select
                    v-model="searchForm.mapType"
                    placeholder="请选择关联匹配方式"
                  >
                    <el-option
                      v-for="item in mapTypeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-tooltip>
                <el-input
                  class="search-input"
                  v-model="searchForm.barCode"
                  clearable
                  placeholder="搜索商品条形码"
                ></el-input>
                <el-button
                  class="search-btn"
                  type="primary"
                  icon="el-icon-search"
                  @click="search"
                  >搜索商品</el-button
                >
                <el-button
                  class="search-btn"
                  type="danger"
                  icon="el-icon-reload"
                  @click="resetSearch"
                  >重置搜索条件</el-button
                >
              </div>
            </el-form>
          </div>
          <div class="wrapper" style="padding-top: 10px">
            <div class="right">
              <el-button @click="addGoodsRoute" type="primary"
                >新品首营申请</el-button
              >
              <el-button
                type="primary"
                @click="exportExcel"
                icon="el-icon-download"
                >导出连锁总部商品列表</el-button
              >
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
            v-loading="loading"
            ref="multipleTable"
            :border="true"
            :stripe="true"
            :data="tableData"
            style="width: 100%"
            size="mini"
            height="calc(100vh - 305px)"
            highlight-current-row
            @current-change="tableCurrentChange"
            :current-row-key="currentRowKey"
          >
            <el-table-column
              align="center"
              width="100"
              prop="mapFlag"
              label="匹配状态"
            >
              <template slot-scope="scope">
                <el-tag
                  style="
                    font-size: 12px;
                    border-radius: 10px;
                    padding: 0 8px;
                    cursor: pointer;
                  "
                  size="mini"
                  :type="scope.row.mapFlag === '1' ? 'success' : 'danger'"
                  effect="dark"
                  >{{ scope.row.mapFlag === "1" ? "已匹配" : "未匹配" }}</el-tag
                >
              </template> </el-table-column
            ><el-table-column width="120" prop="id" label="商品编码">
            </el-table-column>
            <el-table-column width="180" prop="otherName" label="商品名">
            </el-table-column>
            <el-table-column width="180" prop="name" label="通用名">
            </el-table-column>
            <el-table-column width="270" prop="manufacturer" label="生产厂家">
            </el-table-column>
            <el-table-column width="180" prop="format" label="规格">
            </el-table-column>
            <el-table-column width="80" prop="unit" label="单位">
            </el-table-column>
            <el-table-column width="80" prop="dosageForm" label="剂型">
            </el-table-column>
            <el-table-column width="170" prop="license" label="批准文号">
            </el-table-column>
            <el-table-column width="150" prop="barCode" label="商品条形码">
            </el-table-column>
            <el-table-column width="80" prop="mediumPkgSize" label="中包装">
            </el-table-column>
            <el-table-column width="80" prop="bigPkgSize" label="大包装">
            </el-table-column>
            <el-table-column width="80" prop="tax" label="税率">
            </el-table-column>
            <el-table-column width="270" prop="holder" label="上市许可持有人">
            </el-table-column>
            <el-table-column width="120" prop="medicalType" label="医保类型">
            </el-table-column>
            <el-table-column width="120" prop="isImport" label="进口药品">
            </el-table-column>
            <el-table-column width="120" prop="pinyin" label="拼音">
            </el-table-column>
            <el-table-column width="120" prop="category" label="商品分类">
            </el-table-column>
            <el-table-column width="80" prop="remark" label="备注">
            </el-table-column>
            <el-table-column fixed="right" label="操作区" width="120">
              <template slot-scope="scope">
                <el-button
                  style="color: #f56c6c"
                  @click.native.prevent="editGoodsData(scope.row)"
                  type="text"
                  size="small"
                >
                  商品纠错
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="bottom-page-content">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNo"
              :page-sizes="[20, 30, 40, 50]"
              layout=" sizes,prev,pager, next,total"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <match-products-modal ref="matchProductsModal" @refresh="loadData()" />
    <add-goods-modal @uploadSuccess="uploadSuccess" ref="addGoodsModal" />
  </div>
</template>

<script>
import {
  deleteAction,
  getAction,
  postAction,
  putAction,
} from "../../api/manage";
import AddGoodsModal from "./modules/AddGoodsModal.vue";
import MatchProductsModal from "./modules/MatchProductsModal.vue";
export default {
  components: { AddGoodsModal, MatchProductsModal },
  data() {
    return {
      screenIndex: "1",
      loading: false,
      totalCount: 0,
      pageNo: 1,
      pageSize: 20,
      searchForm: {
        searchVal: "",
        manufacturer: "",
        barCode: "",
        mapType: "",
        mapFlag: "",
      },
      mapFlagOptions: [
        { value: "", label: "所有" },
        { value: "1", label: "已匹配" },
        { value: "0", label: "未匹配" },
      ],
      mapTypeOptions: [
        { value: "", label: "所有" },
        { value: "手动", label: "手动匹配" },
        { value: "自动", label: "自动匹配" },
      ],
      tableData: [], // 门店商品列表
      multipleSelection: [],
      currentRow: null,
      currentRowKey: "id", // 假设每行数据都有一个唯一的 id 字段
    };
  },
  created() {
    this.loadData(1);
  },
  methods: {
    addGoodsRoute() {
      this.$router.push({
        path: "/chain/goodsDetail",
        query: { opreateType: "2" },
      });
    },
    auditMatch(item) {
      this.$confirm("确定审批关联？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 关联商品
        let url = "/liansuo/productMendian/mendian/edit";
        let params = {
          id: item.id,
          status: "1",
        };
        putAction(url, params).then((res) => {
          console.log("关联商品返回的数据为", res);
          if (res.code === 200 && res.success) {
            this.$message({
              message: "审批成功！",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            this.loadData(1);
          }
        });
        console.log("确认关联的数据为", params);
      });
    },
    handleClick(val) {
      let screenIndex = val.name;
      if (screenIndex === "1") {
        this.searchForm.status = "";
        this.searchForm.mapFlag = "";
      } else if (screenIndex === "2") {
        this.searchForm.status = "";
        this.searchForm.mapFlag = "1";
      } else if (screenIndex === "3") {
        this.searchForm.status = "";
        this.searchForm.mapFlag = "0";
      } else if (screenIndex === "4") {
        this.searchForm.mapFlag = "1";
        this.searchForm.status = "0";
      }
      console.log("当前选择的筛选状态", screenIndex);
      this.screenIndex = screenIndex;
      this.loadData(1);
    },
    exportExcel() {
      let url = "/liansuo/productMendian/mendian/exportXls";
      postAction(url, this.searchForm).then((res) => {
        if (res.code === 200 && res.success) {
          this.$message({
            type: "success",
            message: "导出成功!",
          });
        }
      });
    },
    batchDelete() {
      let seletecdRow = this.multipleSelection;
      let ids = [];
      seletecdRow.forEach((item) => {
        ids.push(item.id);
      });
      if (ids.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择要删除的数据！",
        });
        return;
      }
      this.$confirm("确定要删除选中的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = "/liansuo/productMendian/mendian/deleteBatch";
          let idsStr = ids.toString();
          deleteAction(url, {
            ids: idsStr,
          }).then((res) => {
            console.log("批量删除商品列表数据为", ids);
            if (res.code === 200 && res.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.loadData(1);
            } else {
              this.$message({
                type: "error",
                message: res.message,
              });
            }
          });
          return;
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "批量删除操作失败",
          });
        });
    },
    tableCurrentChange(val) {
      this.currentRow = val;
    },
    // 点击关联匹配
    showMatchProductsModal(item) {
      this.$refs.matchProductsModal.showModal(item);
    },
    // 修改本地商品库数据
    editGoodsData(item) {
      this.$router.push({
        path: "/chain/goodsDetail",
        query: { id: item.id, opreateType: "2" },
      });
    },
    // 确认匹配
    confirmMatch() {},
    toggleSelection(rows) {
      console.log("点击选中的复选框事件", rows);
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      this.loadData(1);
    },
    resetSearch() {
      this.searchForm = {
        searchVal: "",
        manufacturer: "",
        barCode: "",
        mapType: "",
        mapFlag: "",
        status: "",
      };
      this.screenIndex = "1";
      this.loadData(1);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      let pageNo = this.pageNo;
      this.loadData(pageNo);
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.loadData(val);
    },
    // 上传成功
    uploadSuccess() {
      this.loadData(1);
    },
    loadData(page) {
      let _this = this;
      this.loading = true;
      let url = "/liansuo/productLiansuo/mendian/list";
      let pageSize = this.pageSize;
      let data = {
        ...this.searchForm,
        pageNo: page,
        pageSize: pageSize,
      };
      getAction(url, data).then((res) => {
        _this.loading = false;
        if (res.code === 200 && res.success) {
          _this.tableData = res.result.records;
          _this.totalCount = res.result.total;
        }
      });
    },
    showExcelImportModal() {
      this.$refs.addGoodsModal.showExcelImportModal();
    },
    deleteRow(id) {
      this.$confirm("该操作会删除门店该商品数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = { id: id };
          let url = "/liansuo/productMendian/mendian/delete";
          deleteAction(url, params).then((res) => {
            if (res.code === 200 && res.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.loadData(1);
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "删除操作失败",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 18px;
}
* {
  font-size: 14px;
}
.code-matching {
  height: 100%;
}

.code-matching-title {
  display: flex;
  height: 60px;
  background-color: #24263d;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  .back-account-center {
    position: absolute;
    left: 20px;
  }
}
.dropdown-btn {
  color: #409eff !important;
  background: #ecf5ff !important;
}
.code-matching-content {
  padding: 10px 10px;
  background-color: #ecedf2;
  height: calc(100% - 60px);
  .search-content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    padding: 20px;
    .wrapper {
      width: 100%;
      .left {
        width: 100%;
        .el-input {
          width: 180px;
          min-width: 150px;
          margin-bottom: 10px;
          &.search-manufacturer {
            width: 200px;
          }
        }
        ::v-deep .el-input__inner {
          border-radius: 5px !important;
        }
        .el-select {
          min-width: 100px;
          width: 150px;
          margin-left: 10px;
          margin-bottom: 10px;
        }
        .search-input {
          padding: 0 10px;
          border-radius: 5px;
        }
        .search-btn {
          margin-left: 10px;
          margin-bottom: 10px;
        }
      }
      .right {
        .el-button {
          margin-left: 10px;
          margin-bottom: 10px;
        }

        .dropdown-btn {
          color: #409eff !important;
          background: #ecf5ff !important;
          border-color: #409eff !important;
          &:hover {
            background-color: #409eff !important;
            color: #ffffff !important;
          }
        }
      }
    }
  }
  ::v-deep .el-table th.el-table__cell {
    overflow: hidden;
    user-select: none;
    background-color: #f6f7fa !important;
  }
}
.table-content {
  background-color: #ffffff;
  padding: 0 30px;
  .bottom-page-content {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    .opreate-btn {
      display: flex;
      div {
        width: 30px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: justify;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    ::v-deep .el-pagination__total {
      line-height: 28px !important;
    }
  }
}
::v-deep .el-table-column--selection {
  .el-checkbox {
    padding-left: 10px;
  }
}
/* 全局样式文件，例如 main.css */
::-webkit-scrollbar {
  width: 6px; /* 滚动条宽度 */
}
::-webkit-scrollbar-track {
  background: #f1f1f1; /* 滚动条轨道背景色 */
}

::-webkit-scrollbar-thumb {
  background: #888; /* 滚动条滑块背景色 */
  border-radius: 3px; /* 滑块圆角 */
}

.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555; /* 滑块悬停时背景色 */
}
// ::v-deep .el-table__body-wrapper, ::v-deep .el-scrollbar__wrap {
//   &::-webkit-scrollbar {
//     width: 6px;
//     height: 6px;
//   }
// }
::v-deep .el-input {
  .el-input__clear {
    padding-right: 15px !important;
  }
}
</style>