<template>
  <el-dialog
    title="总部商品匹配"
    :visible.sync="matchDialogVisible"
    width="1150px"
    :before-close="handleClose"
  >
    <div class="modal-content">
      <div class="goods-data">
        <div class="goods-info">
          <el-descriptions
            :column="3"
            style="margin-bottom: 20px"
            title="门店品种信息（双击信息填充到搜索条件栏）"
            border
          >
            <el-descriptions-item>
              <template slot="label"> 商品名称 </template>
              <div
                class="value-text"
                @dblclick="renderSearch('searchVal', goodsInfo.name)"
              >
                {{ goodsInfo.name }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 生产厂家 </template>
              <div
                class="value-text"
                @dblclick="renderSearch('manufacturer', goodsInfo.manufacturer)"
              >
                {{ goodsInfo.manufacturer }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 规格 </template>
              <div
                class="value-text"
                @dblclick="renderSearch('format', goodsInfo.format)"
              >
                {{ goodsInfo.format }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 商品条形码 </template>
              <div
                class="value-text"
                @dblclick="renderSearch('barCode', goodsInfo.barCode)"
              >
                {{ goodsInfo.barCode }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 批准文号 </template>
              <div
                class="value-text"
                @dblclick="renderSearch('license', goodsInfo.license)"
              >
                {{ goodsInfo.license }}
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div class="search-content">
        <el-tooltip effect="dark" content="请输入商品名称搜索" placement="top">
          <el-input
            @input="changeMessage()"
            v-model="searchForm.searchVal"
            placeholder="输入商品名称搜索"
            clearable
          ></el-input>
        </el-tooltip>
        <el-tooltip effect="dark" content="请输入条形码搜索" placement="top">
          <el-input
            v-model="searchForm.barCode"
            placeholder="输入条形码搜索"
            clearable
          ></el-input>
        </el-tooltip>
        <el-tooltip effect="dark" content="请输入批准文号" placement="top">
          <el-input
            @input="changeMessage()"
            v-model="searchForm.license"
            placeholder="输入批准文号"
            clearable
          ></el-input>
        </el-tooltip>
        <el-tooltip effect="dark" content="请输入生产厂家" placement="top">
          <el-input
            @input="changeMessage()"
            v-model="searchForm.manufacturer"
            placeholder="输入生产厂家"
            clearable
          ></el-input>
        </el-tooltip>

        <el-tooltip effect="dark" content="请输入商品规格" placement="top">
          <el-input
            @input="changeMessage()"
            v-model="searchForm.format"
            placeholder="输入商品规格"
            clearable
          ></el-input>
        </el-tooltip>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >搜索</el-button
        >
        <el-button
          @input="changeMessage()"
          type="danger"
          icon="el-icon-refresh-right"
          @click="resetSearch"
          >重置</el-button
        >
      </div>
      <div class="tips-content">
        <div class="tips">
          <span>没有查询到相关总部商品？重新输入搜索条件，或</span>
          <span @click="addLinasuoGoods" class="add-btn">
            <el-icon class="el-icon-circle-plus"></el-icon> 新品首营申请
          </span>
        </div>
      </div>
      <div class="table-content">
        <el-table
          v-loading="loading"
          :border="true"
          :stripe="true"
          :data="tableData"
          style="width: 100%"
          size="mini"
          highlight-current-row
          @current-change="tableCurrentChange"
          :current-row-key="currentRowKey"
        >
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="id" label="商品编码"> </el-table-column>
          <el-table-column prop="name" label="商品名称"> </el-table-column>
          <el-table-column prop="format" label="规格"> </el-table-column>
          <el-table-column prop="manufacturer" label="生产厂家">
          </el-table-column>
          <el-table-column prop="barCode" label="条码"> </el-table-column>
          <el-table-column prop="license" label="批准文号"> </el-table-column>
          <el-table-column prop="mapFlag" label="是否关联">
          </el-table-column>
          <el-table-column prop="mendianInventory" label="门店库存">
          </el-table-column>
          <el-table-column prop="liansuoInventory" label="总部库存">
          </el-table-column>
          <el-table-column align="center" label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                style="color: #409eff"
                @click.native.prevent="matchProducts(scope.row.id)"
                type="text"
                size="small"
                >关联该商品
              </el-button>
              <el-button
                style="color: #f56c6c"
                @click.native.prevent="editLiansuoGoods(scope.row)"
                type="text"
                size="small"
                >纠错信息
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-container">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :hide-on-single-page="true"
            :current-page="pageNo"
            layout="prev,pager, next,total"
            :total="totalCount"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getAction, putAction } from "../../../api/manage";
export default {
  data() {
    return {
      goodsInfo: {},
      loading: false,
      pageSize: 5,
      pageNo: 1,
      totalCount: 0,
      matchDialogVisible: false,
      searchForm: {
        searchVal: "",
        manufacturer: "",
        barCode: "",
        license: "",
        format: "",
      },
      url: {
        list: "/liansuo/productLiansuo/mendian/list",
        edit: "/liansuo/productMendian/mendian/edit",
      },
      tableData: [],
      currentRow: null,
      currentRowKey: "id", // 假设每行数据都有一个唯一的 id 字段
    };
  },
  methods: {
    changeMessage() {
      this.$forceUpdate();
    },
    editLiansuoGoods(item) {
      let matchId = this.goodsInfo.id;
      const routeData = this.$router.resolve({
        path: "/chain/goodsDetail",
        query: { id: item.id, opreateType: "2", matchId: matchId },
      });
      window.open(routeData.href, "_blank");
    },
    addLinasuoGoods() {
      let matchId = this.goodsInfo.id;
      const routeData = this.$router.resolve({
        path: "/chain/goodsDetail",
        query: { opreateType: "2", matchId: matchId },
      });
      window.open(routeData.href, "_blank");
    },
    matchProducts(id) {
      this.$confirm("确定关联该商品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 关联商品
        let params = {
          liansuoId: id,
          id: this.goodsInfo.id,
        };
        console.log("确认关联的数据为", params);
        putAction(this.url.edit, params).then((res) => {
          console.log("关联商品返回的数据为", res);
          if (res.code === 200 && res.success) {
            this.$message({
              message: "关联成功！",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            this.handleClose();
            this.$emit("refresh");
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    renderSearch(param, val) {
      console.log("双击信息栏的数据为", param, val);
      this.searchForm[param] = val;
      this.$forceUpdate();
    },
    tableCurrentChange(val) {
      this.currentRow = val;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      let pageNo = this.pageNo;
      this.loadData(pageNo);
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.loadData(val);
    },
    // 确认关联操作
    confirmMatch() {},
    showModal(item) {
      console.log("点击关联匹配", item);
      this.searchForm = {};
      this.matchDialogVisible = true;
      this.goodsInfo = {
        ...item,
      };
    },
    handleClose() {
      this.matchDialogVisible = false;
    },
    //搜索连锁总部
    search() {
      console.log("搜索连锁总部");
      this.loadData(1);
    },
    loadData(page) {
      let _this = this;
      this.loading = true;
      let url = this.url.list;
      let pageSize = this.pageSize;
      console.log("_this.searchForm+++++", _this.searchForm);
      let data = {
        ..._this.searchForm,
        pageNo: page,
        pageSize: pageSize,
      };
      getAction(url, data).then((res) => {
        _this.loading = false;
        if (res.code === 200 && res.success) {
          _this.tableData = res.result.records;
          _this.totalCount = res.result.total;
        } else {
          _this.tableData = [];
        }
      });
    },
    resetSearch() {
      this.searchForm = {};
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  ::v-deep .el-descriptions__header {
    margin-bottom: 0;
    height: 40px;
    line-height: 40px;
    border: 1px solid #ebeef5;
    border-bottom: none;
    background-color: #f6f7fa;
    .el-descriptions__title {
      font-size: 17px;
      font-weight: bold;
      padding-left: 10px;
    }
  }
  .value-text {
    cursor: pointer !important;
    user-select: none;
  }
  .search-content {
    display: flex;
    align-items: center;
    .el-input {
      width: 200px;
      margin-right: 10px;
      ::v-deep .el-input__inner {
        border-radius: 5px !important;
      }
    }
  }
  .tips-content {
    height: 50px;
    line-height: 50px;
    span {
      font-size: 13px;
      padding-left: 5px;
    }
    .add-btn {
      color: #409eff;
      .el-icon-circle-plus {
        margin: 0 5px;
      }
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .table-content {
    ::v-deep .el-table th.el-table__cell {
      overflow: hidden;
      user-select: none;
      background-color: #f6f7fa !important;
    }
  }
}
.pagination-container {
  margin-top: 20px;
  text-align: center;
  ::v-deep .el-pagination__total {
    line-height: 28px !important;
  }
}
::v-deep .el-dialog__body {
  padding: 20px !important;
}
</style>