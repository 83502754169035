import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { VueAxios } from '@/utils/axios'
import { getUserInfo } from "@/assets/js/common/common";
import { SHOPPING_ACCESS_TOKEN, SALESMAN_TOKEN } from '@/store/mutation-types'
import router from '../router'
import { MessageBox } from 'element-ui';
import { createTouristToken } from '../assets/js/common/common'
const Base64 = require('js-base64').Base64
// 判断配置文件环境  如果是开发模式则使用本地代理
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL  : '/web',
  timeout: 60000
})
const err = (error) => {
  if (error.response) {
    const data = error.response.data
    let token = Vue.ls.get(SHOPPING_ACCESS_TOKEN)
    if (!token) {
      token = createTouristToken()
    }
    switch (error.response.status) {
      case 403:
        alert('拒绝访问')
        break
      case 405:
        if (token && data.message === 'Token失效，请重新登录') {
          // alert('登录超时，请重新登录！')
          setTimeout(function () {
            // store.dispatch('Logout').then(() => {
            //   window.location.reload()
            // })
          }, 1000)
        }
        break
      case 404:
        alert('很抱歉，资源未找到!')
        break
      case 504:
        alert('网络超时!')
        break
      case 401:
        // alert('未授权，请重新登录!')
        if (token) {
          // store.dispatch('Logout').then(() => {
          //   setTimeout(() => {
          //     window.location.reload()
          //   }, 1000)
          // })
        }
        break
      default:
        alert(data.message)
        break
    }
  }
  return Promise.reject(error)
}

service.interceptors.request.use(config => {
  const token = Vue.ls.get(SHOPPING_ACCESS_TOKEN)
  const userInfo = getUserInfo()
  let decodeToken = ''
  if (token) {
    decodeToken = Base64.decode(token)
  } else {
    decodeToken = createTouristToken()
  }
  config.headers['Shopping-Access-Token'] = decodeToken
  if (userInfo) {
    config.headers['ci'] = userInfo.company.id
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

service.interceptors.response.use((response) => {
  const resData = response.data
  if (resData.code === 401 || resData.code === 405) {
    // store.dispatch('Logout').then(() => {
    //   setTimeout(() => {
    //     window.location.reload()
    //   }, 1000)
    // })
  } else {
    return response.data
  }
}, err)

const installer = {
  vm: {},
  install(Vue, router = {}) {
    Vue.use(VueAxios, router, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
