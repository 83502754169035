<template>
  <!-- 操作栏 -->
  <div class="filter-container">
    <div class="code-matching-title">
      {{ title }}
      <div class="back-account-center">
        <a @click="goback" href="javascript:;">
          <el-button type="link" icon="el-icon-arrow-left">返回</el-button>
        </a>
      </div>
    </div>
    <div class="detail-container">
      <div class="detail-content">
        <el-card>
          <div class="opreation-btn">
            <el-button
              v-if="opreateType === '1'"
              type="primary"
              @click="saveGoods"
              >{{
                goodsId && goodsId !== ""
                  ? "修改门店商品数据"
                  : "新增门店商品数据"
              }}</el-button
            >
            <el-button
              v-if="opreateType === '2'"
              type="primary"
              @click="submitEvent(1)"
              >{{
                goodsId && goodsId !== "" ? "提交纠错" : "连锁新品首营申请"
              }}</el-button
            >
            <el-button
              v-if="opreateType === '2' && matchId && matchId !== ''"
              type="primary"
              @click="submitEvent(2)"
              plain
              >提交{{
                goodsId && goodsId !== "" ? "纠错" : "连锁新品首营"
              }}并关联</el-button
            >
            <el-button type="danger" @click="resetForm">重置表单</el-button>
          </div>
        </el-card>
        <el-form
          size="mini"
          ref="goodsForm"
          :rules="rules"
          :model="form"
          label-width="80px"
        >
          <el-card class="base-data">
            <div class="form-container">
              <div @click="showGoodsImgModal" class="goods-img">
                <img
                  v-if="form.picUrl && form.picUrl !== ''"
                  :src="form.picUrl.split(',')[0]"
                  class="avatar"
                />
                <div class="no-img" v-else>
                  <div style="text-align: center">
                    <i class="el-icon-plus"></i>
                    <p>上传图片</p>
                  </div>
                </div>
              </div>
              <div class="goods-base-form">
                <div class="name-box">
                  <el-form-item
                    v-if="opreateType === '1' && (!goodsId || goodsId === '')"
                    label="通用名"
                    prop="name"
                  >
                    <el-tooltip
                      :value="showTipStatus"
                      :manual="true"
                      placement="top"
                    >
                      <div
                        style="font-size: 12px; line-height: 18px"
                        slot="content"
                      >
                        1.您可以手动输入名称，手动输入创建<br />2.也可以输入关键字，从连锁总部商品库中选择，若选择连锁总部商品信息，系统将带出默认字段，自动填充商品数据。
                      </div>
                      <el-select
                        popper-class="match-goods-select"
                        v-model="form.name"
                        @focus="formNameFocus"
                        @blur="formNameBlur"
                        @change="selectedGoods"
                        clearable
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入关键词"
                        :remote-method="getLiansuoGoods"
                        :loading="loading"
                      >
                        <el-option
                          v-for="item in liansuoGoodsList"
                          :key="item.value"
                          :label="item.label"
                          :value="item"
                        >
                          <div class="goods-item">
                            <div class="goods-box">
                              <p class="name">
                                【{{ item.id }}】{{ item.name }}
                              </p>
                              <p class="goods-data">
                                <span v-if="item.format">{{
                                  item.format
                                }}</span>
                                <span v-if="item.manufacturer">{{
                                  item.manufacturer
                                }}</span>
                                <span v-if="item.barCode">{{
                                  item.barCode
                                }}</span>
                                <span v-if="item.license">{{
                                  item.license
                                }}</span>
                              </p>
                              <p>
                                <el-tag
                                  style="
                                    font-size: 12px;
                                    border-radius: 10px;
                                    padding: 0 8px;
                                    cursor: pointer;
                                  "
                                  size="mini"
                                  type="danger"
                                  effect="dark"
                                  >{{ item.prescriptionType }}</el-tag
                                >
                              </p>
                            </div>

                            <el-tag
                              style="
                                font-size: 12px;
                                border-radius: 10px;
                                padding: 0 8px;
                                cursor: pointer;
                              "
                              size="mini"
                              type="'success"
                              effect="dark"
                              >连锁商品库</el-tag
                            >
                          </div>
                        </el-option>
                      </el-select>
                    </el-tooltip>
                  </el-form-item>
                  <el-form-item
                    v-else-if="
                      opreateType === '2' || (goodsId && goodsId !== '')
                    "
                    label="通用名"
                    prop="name"
                  >
                    <el-input
                      v-model="form.name"
                      @blur="formNameBlur"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="base-other-data">
                  <div class="row">
                    <el-form-item v-if="opreateType === '2'" label="商品编码">
                      <el-input
                        disabled
                        placeholder="商品编码自动生成"
                        v-model="form.id"
                      ></el-input>
                    </el-form-item>
                    <el-form-item v-else label="商品编码">
                      <el-input
                        :disabled="goodsId && goodsId !== '' ? true : false"
                        placeholder="填写门店ERP系统商品编码"
                        v-model="form.id"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="商品名称">
                      <el-input clearable v-model="form.otherName"></el-input>
                    </el-form-item>
                    <el-form-item label="规格" prop="format">
                      <el-input clearable v-model="form.format"></el-input>
                    </el-form-item>
                    <el-form-item label="生产厂家" prop="manufacturer">
                      <el-input
                        clearable
                        v-model="form.manufacturer"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="row">
                    <el-form-item label="拼音码">
                      <el-input clearable v-model="form.pinyin"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="是否有条码" prop="name">
                  <el-select v-model="form.hasBarCode">
                    <el-option label="有条码" value="1"> </el-option>
                    <el-option label="无条码" value="0"> </el-option>
                  </el-select>
                </el-form-item> -->
                    <el-form-item label="条码" prop="barCode">
                      <el-input clearable :maxlength="14" v-model="form.barCode"></el-input>
                    </el-form-item>
                    <el-form-item label="批准文号" prop="license">
                      <el-input clearable v-model="form.license"></el-input>
                    </el-form-item>
                    <el-form-item label="产地">
                      <el-input clearable v-model="form.place"></el-input>
                    </el-form-item>
                  </div>
                  <div class="row"></div>
                </div>
              </div>
            </div>
          </el-card>
          <el-card class="other-data">
            <div class="form-container">
              <div class="title">分类</div>
              <div class="other-data-form">
                <div class="row">
                  <el-form-item label="商品分类">
                    <el-cascader
                      clearable
                      v-model="form.category"
                      :options="categoryOptions"
                      @change="handleChange"
                    ></el-cascader>
                  </el-form-item>
                  <el-form-item label-width="125px" label="所属经营范围">
                    <el-select v-model="form.businessScope">
                      <el-option
                        clearable
                        v-for="item in businessScopeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label-width="125px"
                    label="处方分类"
                    prop="prescriptionType"
                  >
                    <el-select clearable v-model="form.prescriptionType">
                      <el-option
                        v-for="item in prescriptionOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </div>

            <div class="form-container">
              <div class="title">属性</div>
              <div class="other-data-form">
                <div class="row">
                  <el-form-item label="单位" prop="unit">
                    <el-input clearable v-model="form.unit"></el-input>
                  </el-form-item>
                  <el-form-item label-width="125px" label="中包装">
                    <el-input clearable v-model="form.mediumPkgSize"></el-input>
                  </el-form-item>
                  <el-form-item label-width="125px" label="大包装">
                    <el-input clearable v-model="form.bigPkgSize"></el-input>
                  </el-form-item>
                  <el-form-item label-width="125px" label="剂型">
                    <el-select clearable v-model="form.dosageForm">
                      <el-option
                        v-for="item in dosageOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="row">
                  <el-form-item label="税率">
                    <el-input
                      @input="handleTaxInput"
                      clearable
                      v-model="form.tax"
                      :min="0"
                      :max="1"
                    >
                    </el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="form-container">
              <div class="title">质量</div>
              <div class="other-data-form">
                <el-form-item label="养护分类">
                  <el-select clearable v-model="form.yanghufenlei">
                    <el-option
                      v-for="item in yanghufenleiOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label-width="125px" label="存储条件">
                  <el-select clearable v-model="form.storeWay">
                    <el-option
                      v-for="item in storeWayOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label-width="125px"
                  style="margin-right: 70px"
                  label="保质期（月）"
                >
                  <el-input
                    clearable
                    v-model="form.qualityTime"
                    @input="handleInputNumber"
                    :min="0"
                    :max="100"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label-width="125px" label="上市许可证持有人">
                  <el-input clearable v-model="form.holder"></el-input>
                </el-form-item>
                <div class="row">
                  <el-form-item label="药监编码">
                    <el-input
                      clearable
                      v-model="form.yaojianLicense"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label-width="125px" label="生产许可证号">
                    <el-input
                      clearable
                      placeholder="请输入生产许可证号"
                      v-model="form.productionLicenseNumber"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label-width="125px" label="批文号有效期">
                    <div class="date-view">
                      <el-date-picker
                        clearable
                        v-model="form.startDate"
                        type="date"
                        placeholder="选择日期"
                        :picker-options="productionPickerOptions"
                        @change="handleProductionDateChange"
                      >
                      </el-date-picker>
                      <span style="margin: 0 5px">-</span>
                      <el-date-picker
                        clearable
                        v-model="form.endDate"
                        type="date"
                        placeholder="选择日期"
                        :picker-options="expiryPickerOptions"
                      >
                      </el-date-picker>
                    </div>
                  </el-form-item>
                  <el-form-item label-width="99px" label="器械DI码">
                    <el-input
                      clearable
                      placeholder="请输入器械DI码"
                      v-model="form.deviceDICode"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="form-container">
              <div class="title">标签</div>
              <div class="other-data-form">
                <el-form-item label="含麻黄碱">
                  <el-switch
                    active-value="是"
                    inactive-value="否"
                    v-model="form.mahuangFlag"
                    active-color="#13ce66"
                  >
                  </el-switch>
                </el-form-item>
                <el-form-item label="冷链商品">
                  <el-switch
                    active-value="是"
                    inactive-value="否"
                    v-model="form.coldFlag"
                    active-color="#13ce66"
                  >
                  </el-switch>
                </el-form-item>
                <el-form-item label="四类药品">
                  <el-switch
                    active-value="是"
                    inactive-value="否"
                    v-model="form.sileiFlag"
                    active-color="#13ce66"
                  >
                  </el-switch>
                </el-form-item>
                <el-form-item label="国家限售">
                  <el-switch
                    active-value="是"
                    inactive-value="否"
                    v-model="form.xianshouFlag"
                    active-color="#13ce66"
                  >
                  </el-switch>
                </el-form-item>
                <el-form-item label="医保商品">
                  <el-switch
                    active-value="是"
                    inactive-value="否"
                    v-model="form.yibaoFlag"
                    active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
                <el-form-item label="境外药品">
                  <el-switch
                    active-value="是"
                    inactive-value="否"
                    v-model="form.jinkouFlag"
                    active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
                <el-form-item label="孕妇慎用">
                  <el-switch
                    active-value="是"
                    inactive-value="否"
                    v-model="form.yunfusyFlag"
                    active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
                <el-form-item label="含兴奋剂">
                  <el-switch
                    active-value="是"
                    inactive-value="否"
                    v-model="form.xingfenFlag"
                    active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
                <el-form-item label-width="100px" label="凭处方销售">
                  <el-switch
                    active-value="是"
                    inactive-value="否"
                    v-model="form.chufangFlag"
                    active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
              </div>
            </div>
            <div class="form-container">
              <div class="title">其他</div>
              <div class="other-data-form">
                <div class="row">
                  <!-- <el-form-item label="资料来源">
                    <el-input disabled v-model="form.source"></el-input>
                  </el-form-item> -->
                  <el-form-item label="创建时间">
                    <el-input
                      disabled
                      v-model="form.createTime"
                      placeholder="自动生成"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label-width="125px" label="创建者">
                    <el-input disabled v-model="form.createBy"></el-input>
                  </el-form-item>
                  <el-form-item
                    disabled
                    v-if="opreateType == '1'"
                    label-width="125px"
                    label="连锁平台商品ID"
                  >
                    <el-input disabled v-model="form.liansuoId"></el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="opreateType == '2' && matchId !== '' && goodsId === ''"
                    label-width="125px"
                    label="门店ERP商品ID"
                    prop="erpId"
                  >
                    <el-input v-model="form.erpId"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </el-card>
        </el-form>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <add-goods-image-modal
      @preViewGoodsImg="preViewGoodsImg"
      @submitGoodsImg="submitGoodsImg"
      ref="addGoodsImageModal"
    />
  </div>
</template>

<script>
import { getAction, postAction, putAction } from "../../api/manage";
// const PinyinPro = require("pinyin-pro");
import { pinyin } from "pinyin-pro";
import AddGoodsImageModal from "./modules/AddGoodsImageModal.vue";
export default {
  components: { AddGoodsImageModal },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: "",
      showTipStatus: false,
      categoryOptions: [],
      productionPickerOptions: {},
      expiryPickerOptions: {},
      form: {
        name: "",
        id: "",
        otherName: "",
        pinyin: "",
        dosageForm: "",
        tax: "",
        unit: "",
        holder: "",
        license: "",
        category: "", // 商品分类
        hasBarCode: "1", // 是否带条码
        businessScope: "", // 经营范围
        prescriptionType: "", // 处方类型
        mediumPkgSize: "", // 中包装数量
        bigPkgSize: "", // 大包装数量
        yanghufenlei: "", //  养护类别
        storeWay: "", // 存储条件
        qualityTime: "", // 保质期
        licenseExpire: "", // 有效期
        productionLicenseNumber: "", // 生产许可证号
        deviceDICode: "", // 器械DI码
        mahuangFlag: false, // 是否含麻黄碱
        coldFlag: false, // 冷链商品
        sileiFlag: false, // 四类药品
        xianshouFlag: false, // 国家限售
        yibaoFlag: false, // 医保商品
        jinkouFlag: false, // 境外药品
        yunfusyFlag: false, // 孕妇慎用
        xingfenFlag: false, // 含兴奋剂
        chufangFlag: false, // 凭处方销售
        // source: "手工新增", // 资料来源
        createTime: "",
        createBy: "",
        liansuoId: "",
        imageUrl: "",
      },
      url: {
        linasuoQueryId: "/liansuo/productLiansuo/mendian/queryById",
        liansuoAdd: "/liansuo/productLiansuo/mendian/add",
        liansuoEdit: "/liansuo/productLiansuo/mendian/edit",
        queryId: "/liansuoproductMendian/mendian/queryById",
        add: "/liansuo/productMendian/mendian/add",
        edit: "/liansuo/productMendian/mendian/edit",
      },
      liansuoGoodsList: [],
      storeWayOptions: [
        { label: "常温", value: "常温" },
        { label: "阴凉", value: "阴凉" },
        { label: "冷藏", value: "冷藏" },
        { label: "冷冻", value: "冷冻" },
        { label: "避光", value: "避光" },
      ],
      yanghufenleiOptions: [
        { label: "无需养护", value: "无需养护" },
        { label: "一般养护", value: "一般养护" },
        { label: "重点养护", value: "重点养护" },
      ],
      businessScopeOptions: [
        {
          value: "中成药",
          label: "中成药",
        },
        {
          value: "中药饮片",
          label: "中药饮片",
        },
        {
          value: "化学药制剂",
          label: "化学药制剂",
        },
        {
          value: "抗生素制剂",
          label: "抗生素制剂",
        },
        {
          value: "生化药品",
          label: "生化药品",
        },
        {
          value: "生物制品（疫苗除外）",
          label: "生物制品（疫苗除外）",
        },
        {
          value: "二类精神药品",
          label: "二类精神药品",
        },
        {
          value: "含麻药品",
          label: "含麻药品",
        },
        {
          value: "注射制剂",
          label: "注射制剂",
        },
        {
          value: "食品",
          label: "食品",
        },
        {
          value: "保健食品",
          label: "保健食品",
        },
        {
          value: "含婴幼儿配方乳制品",
          label: "含婴幼儿配方乳制品",
        },
        {
          value: "一类医疗器械",
          label: "一类医疗器械",
        },
        {
          value: "二类医疗器械",
          label: "二类医疗器械",
        },
        {
          value: "三类医疗器械",
          label: "三类医疗器械",
        },
        {
          value: "普通化妆品",
          label: "普通化妆品",
        },
        {
          value: "特殊化妆品",
          label: "特殊化妆品",
        },
        {
          value: "消毒产品",
          label: "消毒产品",
        },
        {
          value: "保健用品",
          label: "保健用品",
        },
        {
          value: "日用百货",
          label: "日用百货",
        },
        {
          value: "物料服务",
          label: "物料服务",
        },
        {
          value: "西药",
          label: "西药",
        },
        {
          value: "医疗用毒性药品",
          label: "医疗用毒性药品",
        },
        {
          value: "罂粟壳",
          label: "罂粟壳",
        },
        {
          value: "农副产品",
          label: "农副产品",
        },
        {
          value: "其他特殊食品",
          label: "其他特殊食品",
        },
        {
          value: "中药材",
          label: "中药材",
        },
        {
          value: "化学原料药",
          label: "化学原料药",
        },
        {
          value: "抗生素原料药",
          label: "抗生素原料药",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      prescriptionOptions: [
        {
          value: "非处方药",
          label: "非处方药",
        },
        {
          value: "处方药",
          label: "处方药",
        },
        {
          value: "甲类非处方药",
          label: "甲类非处方药",
        },
        {
          value: "乙类非处方药",
          label: "乙类非处方药",
        },
        {
          value: "其它",
          label: "其它",
        },
      ],
      dosageOptions: [
        {
          value: "片剂",
          label: "片剂",
        },
        {
          value: "注射剂",
          label: "注射剂",
        },
        {
          value: "胶囊剂",
          label: "胶囊剂",
        },
        {
          value: "颗粒剂",
          label: "颗粒剂",
        },
        {
          value: "眼用制剂",
          label: "眼用制剂",
        },
        {
          value: "鼻用制剂",
          label: "鼻用制剂",
        },
        {
          value: "栓剂",
          label: "栓剂",
        },
        {
          value: "丸剂",
          label: "丸剂",
        },
        {
          value: "乳膏剂",
          label: "乳膏剂",
        },
        {
          value: "糊剂",
          label: "糊剂",
        },
        {
          value: "吸入制剂",
          label: "吸入制剂",
        },
        {
          value: "喷雾剂",
          label: "喷雾剂",
        },
        {
          value: "气雾剂",
          label: "气雾剂",
        },
        {
          value: "凝胶剂",
          label: "凝胶剂",
        },
        {
          value: "散剂",
          label: "散剂",
        },
        {
          value: "糖浆剂",
          label: "糖浆剂",
        },
        {
          value: "搽剂",
          label: "搽剂",
        },
        {
          value: "涂剂",
          label: "涂剂",
        },
        {
          value: "涂膜剂",
          label: "涂膜剂",
        },
        {
          value: "酊剂",
          label: "酊剂",
        },
        {
          value: "贴剂",
          label: "贴剂",
        },
        {
          value: "贴膏剂",
          label: "贴膏剂",
        },
        {
          value: "口服溶液剂",
          label: "口服溶液剂",
        },
        {
          value: "植入剂",
          label: "植入剂",
        },
        {
          value: "膜剂",
          label: "膜剂",
        },
        {
          value: "耳用制剂",
          label: "耳用制剂",
        },
        {
          value: "洗剂",
          label: "洗剂",
        },
        {
          value: "冲洗剂",
          label: "冲洗剂",
        },
        {
          value: "灌肠剂",
          label: "灌肠剂",
        },
        {
          value: "合剂",
          label: "合剂",
        },
        {
          value: "锭剂",
          label: "锭剂",
        },
        {
          value: "煎膏剂",
          label: "煎膏剂",
        },
        {
          value: "胶剂",
          label: "胶剂",
        },
        {
          value: "酒剂",
          label: "酒剂",
        },
        {
          value: "膏药",
          label: "膏药",
        },
        {
          value: "露剂",
          label: "露剂",
        },
        {
          value: "茶剂",
          label: "茶剂",
        },
        {
          value: "流浸膏剂",
          label: "流浸膏剂",
        },
        {
          value: "其他",
          label: "其他",
        },
        {
          value: "饮片",
          label: "饮片",
        },
        {
          value: "粉剂",
          label: "粉剂",
        },
        {
          value: "甘油剂",
          label: "甘油剂",
        },
        {
          value: "干混悬剂",
          label: "干混悬剂",
        },
        {
          value: "含漱液",
          label: "含漱液",
        },
        {
          value: "混悬液",
          label: "混悬液",
        },
        {
          value: "计生",
          label: "计生",
        },
        {
          value: "器械",
          label: "器械",
        },
        {
          value: "日用品",
          label: "日用品",
        },
        {
          value: "外用溶液",
          label: "外用溶液",
        },
        {
          value: "赠品",
          label: "赠品",
        },
        {
          value: "软膏剂",
          label: "软膏剂",
        },
        {
          value: "滴剂",
          label: "滴剂",
        },
        {
          value: "溶液剂",
          label: "溶液剂",
        },
        {
          value: "乳液剂",
          label: "乳液剂",
        },
        {
          value: "膏剂",
          label: "膏剂",
        },
        {
          value: "食品",
          label: "食品",
        },
        {
          value: "水剂",
          label: "水剂",
        },
        {
          value: "灸剂",
          label: "灸剂",
        },
        {
          value: "糖剂",
          label: "糖剂",
        },
        {
          value: "擦剂",
          label: "擦剂",
        },
        {
          value: "滴眼剂",
          label: "滴眼剂",
        },
        {
          value: "滴鼻剂",
          label: "滴鼻剂",
        },
        {
          value: "滴耳剂",
          label: "滴耳剂",
        },
        {
          value: "眼膏剂",
          label: "眼膏剂",
        },
        {
          value: "橡胶膏剂",
          label: "橡胶膏剂",
        },
        {
          value: "针剂",
          label: "针剂",
        },
        {
          value: "乳剂",
          label: "乳剂",
        },
        {
          value: "油剂",
          label: "油剂",
        },
        {
          value: "熨剂",
          label: "熨剂",
        },
        {
          value: "熏剂",
          label: "熏剂",
        },
        {
          value: "棒剂",
          label: "棒剂",
        },
        {
          value: "口服混悬剂",
          label: "口服混悬剂",
        },
      ],
      loading: false,

      rules: {
        name: [
          { required: true, message: "请输入商品通用名", trigger: "blur" },
        ],
        format: [
          { required: true, message: "请填写商品规格", trigger: "blur" },
        ],
        barCode: [
          {
            required: true,
            message: "请填写条码数据，无条码填：'无'",
            trigger: "blur",
          },
          {
            pattern: /^无$|^\d{12}$|^\d{13}$|^\d{14}$|^\d{15}$/,
            message: "条码输入不合法",
            trigger: "blur",
          },
        ],
        manufacturer: [
          { required: true, message: "请填写商品生产厂家", trigger: "blur" },
        ],
        license: [
          { required: true, message: "请填写批准文号", trigger: "blur" },
        ],
        unit: [{ required: true, message: "请填写商品单位", trigger: "blur" }],
        tax: [
          { required: true, message: "税率不能为空", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("税率不能为空"));
              }
              if (!Number.isFinite(value) || value <= 0 || value > 100) {
                callback(new Error("税率必须是一个在1至100之间的数字"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      opreateType: "1",
      title: "新增门店商品",
      matchId: "", // 关联的门店品种ID
      goodsId: "",
    };
  },
  computed: {
    imageUrl() {
      let picUrl = this.form.picUrl;
      if (picUrl && picUrl !== "") {
        let goodsImgFirst = picUrl.split(",")[0];
        return goodsImgFirst;
      } else {
        return "";
      }
    },
  },
  created() {
    let goodsId = this.$route.query.id;
    // opreateType 1、门店商品添加修改  2、连锁库商品纠错
    let opreateType = this.$route.query.opreateType;
    let matchId = this.$route.query.matchId;
    if (matchId && matchId !== "") {
      this.matchId = matchId;
    }
    if (opreateType && opreateType !== "") {
      this.opreateType = opreateType;
    }
    if (goodsId && goodsId !== "") {
      this.goodsId = goodsId;
    }
    if (goodsId && goodsId !== "") {
      if (opreateType === "2") {
        this.title = "连锁库商品纠错";
      } else {
        this.title = "门店商品修改";
      }
      this.getGoodsDetail(goodsId);
    } else {
      if (opreateType === "2") {
        this.title = "连锁总库新品首营申请";
        this.getMatchGoodsDetail(matchId);
      } else {
        this.title = "门店商品添加";
      }
    }
  },

  methods: {
    async getMatchGoodsDetail(matchId) {
      let url = this.url.queryId;
      let data = {
        id: matchId,
      };
      let res = await getAction(url, data);
      if (res.code === 200 && res.success) {
        res.result.erpId = res.result.id;
        res.result.id = "";
        this.form = res.result;
      }
    },
    goback() {
      this.$router.go(-1);
    },
    getGoodsDetail(goodsId) {
      let params = {
        id: goodsId,
      };
      let url = "";
      if (this.opreateType === "2") {
        url = this.url.linasuoQueryId;
      } else {
        url = this.url.queryId;
      }
      getAction(url, params).then((res) => {
        console.log("商品详情", res);
        if (res.success) {
          this.form = res.result;
          // this.form.tax = this.form.tax.toString();
          // this.form.qualityTime = this.form.qualityTime.toString();
        }
      });
    },
    handleTaxInput(value) {
      this.form.tax = value.replace(/[^\d.]/g, "");
    },
    handleProductionDateChange(productionDate) {
      if (productionDate) {
        this.expiryPickerOptions.disabledDate = (time) => {
          return time.getTime() <= productionDate.getTime();
        };
      } else {
        this.expiryPickerOptions.disabledDate = () => false;
      }
    },
    handleInputNumber(value) {
      this.form.qualityTime = value.replace(/[^\d]|^0(?!$)/g, "");
    },
    submitGoodsImg(url) {
      console.log("当前上传的图片地址为123", url);
      if (url && url !== "") {
        this.form.picUrl = url;
        console.log("当前上传的图片地址为form.picUrl", this.form.picUrl);
      } else {
        this.form.picUrl = "";
      }
      this.$forceUpdate();
    },
    preViewGoodsImg(imgUrl) {
      this.dialogImageUrl = imgUrl;
      this.dialogVisible = true;
    },
    showGoodsImgModal() {
      let picUrl = this.form.picUrl;
      this.$refs.addGoodsImageModal.show(picUrl);
    },
    formNameFocus() {
      this.showTipStatus = true;
      this.liansuoGoodsList = [];
    },
    formNameBlur() {
      this.showTipStatus = false;
      let name = this.form.name;
      if (name !== "") {
        this.generatePinyin(name);
      }
    },
    generatePinyin(value) {
      console.log("输入的值为", value);
      this.form.pinyin = pinyin(value, {
        pattern: "first",
        toneType: "none",
      }).replaceAll(" ", "");
    },
    selectedGoods(item) {
      console.log("选中的商品为", item);
      item.createTime = "";
      item.id = "";
      this.form.name = item.name;
      this.form = {
        ...item,
        liansuoId: item.id,
      };
      console.log("this.form.name++++++++", this.form.name);
      if (this.form.name && this.form.name !== "") {
        this.generatePinyin(this.form.name);
      }
    },
    async getLiansuoGoods(query) {
      console.log("当前搜索的商品名称为", query);
      this.form.name = query.trim();
      if (query !== "") {
        let params = {
          searchVal: query,
          pageNo: 1,
          pageSize: 1000,
        };
        let url = "/liansuo/productLiansuo/mendian/list";
        let res = await getAction(url, params);
        console.log("返回的数据为", res);
        if (res.code === 200 && res.success && res.result) {
          this.liansuoGoodsList = res.result.records;
        } else {
          this.liansuoGoodsList = [];
        }
      } else {
        this.liansuoGoodsList = [];
        this.$forceUpdate();
      }
    },
    submitForm() {},
    handleChange() {},
    resetForm() {
      this.$refs.goodsForm.resetFields();
    },
    // 新增/修改门店商品
    saveGoods() {
      let _this = this;
      this.$refs.goodsForm.validate((valid) => {
        if (valid) {
          console.log("提交的商品数据为", _this.form);
          let form = _this.form;
          if (_this.goodsId && _this.goodsId !== "") {
            let url = _this.url.edit;
            putAction(url, form).then((res) => {
              console.log("返回的数据为", res);
              if (res.success) {
                _this.$message({
                  message: "修改门店商品成功",
                  type: "success",
                });
                _this.resetForm();
                _this.$router.push({
                  path: "/chain/goodsList",
                });
              } else {
                _this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            });
          } else {
            let url = _this.url.add;
            postAction(url, form).then((res) => {
              console.log("返回的数据为", res);
              if (res.success) {
                _this.$message({
                  message: "新增门店商品成功",
                  type: "success",
                });
                _this.resetForm();
                _this.$router.push({
                  path: "/chain/goodsList",
                });
              } else {
                _this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitEvent(type) {
      let _this = this;
      this.$refs.goodsForm.validate((valid) => {
        if (valid) {
          console.log("提交的商品数据为", _this.form);
          let form = _this.form;
          if (_this.goodsId && _this.goodsId !== "") {
            let url = _this.url.liansuoEdit;
            if (type == "2") {
              form.erpId = _this.matchId;
            }
            putAction(url, form).then((res) => {
              console.log("返回的数据为", res);
              if (res.success) {
                _this.$message({
                  message: "连锁商品纠错申请提交完成",
                  type: "success",
                });
                _this.resetForm();
                _this.$router.push({
                  path: "/chain/goodsList",
                });
              } else {
                _this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            });
          } else {
            form.id = _this.goodsId;
            if (type == "2") {
              form.erpId = _this.matchId;
            }
            let url = _this.url.liansuoAdd;
            postAction(url, form).then((res) => {
              console.log("返回的数据为", res);
              if (res.success) {
                _this.$message({
                  message: "连锁新品首营提交成功",
                  type: "success",
                });
                _this.resetForm();
                _this.$router.push({
                  path: "/chain/goodsList",
                });
              } else {
                _this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveAndAddGoods() {},
  },
};
</script>
<style lang="scss">
.match-goods-select {
  .el-select-dropdown__item {
    height: auto !important;
    background: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f5f5f5;
    &:hover {
      background: #dbe2fd;
    }
  }
  .goods-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name {
      font-weight: bold;
      color: #141414;
    }
    .goods-data {
      max-width: 500px;
      line-height: 15px;
      white-space: normal;
      span {
        font-size: 13px;
        color: #595959;
        &::after {
          content: "|";
          display: inline-block;
          margin: 0 5px;
        }
        &:last-child::after {
          display: none;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
* {
  font-size: 14px;
}
.el-form-item {
  margin-right: 30px;
  float: left;
  ::v-deep .el-form-item__label {
    height: 30px;
    line-height: 30px;
    color: #141414;
  }
  ::v-deep .el-input__inner {
    width: 190px;
    border-radius: 4px !important;
    height: 30px;
    line-height: 30px;
    padding-right: 15px;
  }
}
.name-box {
  ::v-deep .el-input__inner {
    width: 580px !important;
  }
}
.date-view {
  ::v-deep .el-input__inner {
    padding-right: 10px;
    padding-left: 26px;
    width: 120px;
    font-size: 12px;
  }
  ::v-deep .el-date-editor.el-input {
    width: 120px;
  }
}
.row {
  width: 100%;
  display: flex;
}
.filter-container {
  .code-matching-title {
    display: flex;
    height: 60px;
    background-color: #24263d;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: bold;
    color: #ffffff;
    position: relative;
    .back-account-center {
      position: absolute;
      left: 20px;
    }
  }
  .detail-container {
    height: calc(100vh - 60px);
    background-color: #ecedf2;
    padding: 10px;
    .base-data,
    .other-data {
      min-width: 1460px;
      margin-top: 10px;
      .form-container {
        width: 100%;
        float: left;
        .other-data-form {
          padding-top: 20px;
          ::v-deep .el-form-item__label {
          }
        }
        .title {
          height: 20px;
          font-family: Microsoft YaHei;
          font-size: 17px;
          line-height: 20px;
          color: #141414;
          font-weight: 700;
          position: relative;
          cursor: pointer;
          &::before {
            display: block;
            content: "";
            position: absolute;
            width: 5px;
            height: 20px;
            background-color: #18a97b;
            border-radius: 3px;
            top: 50%;
            transform: translateY(-50%);
            left: -20px;
          }
        }
      }
    }
    .base-data {
      .form-container {
        display: flex;
        .goods-img {
          width: 135px;
          height: 135px;
          display: inline-block;
          border-radius: 5px;
          border: 1px dotted #ccc;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            border-radius: 5px;
            width: 133px;
            height: auto;
            object-fit: fill;
          }
          .no-img {
            width: 135px;
            height: 135px;
            display: flex;
            align-items: center;
            justify-content: center;
            div {
              i,
              p {
                &.el-icon-plus {
                  font-size: 20px;
                }
                color: #737373;
              }
            }
          }
        }
        .goods-base-form {
          padding-left: 20px;

          .name-box {
            width: 100%;
            float: left;
          }
        }
      }
    }
    .other-data {
      padding-bottom: 25px;
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #18a97b !important;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.opreation-btn {
  min-width: 1460px;
  ::v-deep .el-button--primary {
    &.is-plain {
      color: #18a97b !important;
      background: #ffffff !important;
      border-color: #18a97b !important;
      &:hover {
        background-color: #18a97b !important;
        color: #ffffff !important;
      }
    }
  }
}
</style>