<template>
  <div class="home-page">
      <router-view>
      </router-view>
  </div>
</template>

<script>
export default {
  name: "BuyOrderImport"
};
</script>
<style lang="scss" scoped>
</style>