<template>
  <el-dialog
    title="EXCEL导入销售明细"
    :visible.sync="uploadDialogVisible"
    width="600px"
    :before-close="handleClose"
  >
    <div class="upload-content" v-loading="loading">
      <p class="upload-title">
        1.请先下载<a
          href="javascript:;"
          @click="
            createDownloadFile(
              '/resources/template/门店本地销售出库明细上传模板.xls',
              '销售明细导入模版'
            )
          "
          >EXCEL模版</a
        >，并按照模版本内要求完成填写。
        <a
          href="javascript:;"
          >其中模版红色部分为必填项。导入后请核对成功数量是否与Excel表中数量一致</a
        >
      </p>
      <div class="upload-btn">
        <el-upload
          ref="upload"
          class="upload-demo"
          drag
          :on-success="uploadSuccess"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-change="handleChange"
          :file-list="currentFile"
          :headers="{ 'Shopping-Access-Token': accessToken }"
          accept=".csv,.xls,.xlsm,.xlsx"
          action="/web/liansuo/mendian/mendianSaleOrderItem/importExcel"
          :auto-upload="false"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将商品列表文件拖到此处，或<em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            只能上传csv,xls,xlsm,xlsx文件
          </div>
        </el-upload>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submitUpload">{{
        loading ? "正在导入..." : "确定导入"
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAction } from "../../../api/manage";
import { getUserInfo } from "../../../assets/js/common/common";
export default {
  data() {
    return {
      loading: false,
      accessToken: "",
      uploadDialogVisible: false,
      currentFile: [],
    };
  },
  created() {
    const userInfo = getUserInfo();
    if (!userInfo) {
      this.$alert("请登录后操作!", "提示信息：");
      this.$router.push("/login");
      return false;
    } else {
      this.accessToken = userInfo.token;
    }
  },
  methods: {
    showExcelImportModal() {
      this.uploadDialogVisible = true;
      this.loading = false;
    },
    loadData(page) {
      let _this = this;
      this.loading = true;
      let url = "/liansuo/mendian/mendianSaleOrderItem/list";
      let pageSize = this.pageSize;
      let data = {
        ...this.searchForm,
        pageNo: page,
        pageSize: pageSize,
      };
      getAction(url, data).then((res) => {
        _this.loading = false;
        if (res.code === 200 && res.success) {
          _this.tableData = res.result.records;
          _this.totalCount = res.result.total;
        }
      });
    },
    submitUpload() {
      if (this.currentFile) {
        this.loading = true;
        this.$refs.upload.submit();
      } else {
        this.$message.error("请先选择文件");
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleChange(file, fileList) {
      console.log("上传文件修改");
      this.currentFile = [file];
      this.$forceUpdate();
    },
    handlePreview(file) {
      console.log(file);
    },
    // 创建下载地址
    createDownloadFile(url, fileName = "文件下载") {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", fileName + ".xls");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); //下载完成移除元素
      window.URL.revokeObjectURL(url); //释放掉blob对象
    },
    uploadSuccess(res, file, fileList) {
      this.currentFile = [file];
      console.log("门店上传商品列表数据完成", res);
      if (res.code === 200 && res.success) {
        this.loading = false;
        this.$message.success(res.message);
        this.uploadDialogVisible = false;
        this.$emit("uploadSuccess", res);
      } else {
        this.loading = false;
        this.$message.error(res.message);
      }
    },
    handleClose() {
      this.currentFile = [];
      this.$refs.upload.clearFiles();
      this.uploadDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-title {
  margin-bottom: 20px;
  font-size: 15px;
  a {
    color: $act-color;
    text-decoration: underline;
    padding: 0 3px;
  }
}
</style>