<template>
  <div class="code-matching">
    <div class="code-matching-title">
      {{ userInfo.name ? userInfo.name + '-' : '' }}门店销售明细表
      <div class="back-account-center">
        <router-link to="/myAccount">
          <el-button type="link" icon="el-icon-arrow-left"
            >返回个人中心</el-button
          >
        </router-link>
      </div>
    </div>
    <div class="code-matching-content">
      <div class="code-matching-main">
        <div class="search-content">
          <div class="wrapper">
            <el-form @keyup.enter.native="search">
              <div class="left">
                <el-input
                  class="search-input"
                  clearable
                  v-model="searchForm.searchVal"
                  placeholder="搜索商品名称"
                ></el-input>
                <el-input
                  class="search-manufacturer"
                  clearable
                  v-model="searchForm.manufacturer"
                  placeholder="搜索商品生产商家"
                ></el-input>
                <!-- <el-tooltip
                effect="dark"
                content="请选择商品关联匹配状态"
                placement="top"
              >
                <el-select
                  v-model="searchForm.mapFlag"
                  placeholder="请选择匹配状态"
                >
                  <el-option
                    v-for="item in mapFlagOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option> </el-select
              ></el-tooltip> -->
                <el-input
                  class="search-input"
                  v-model="searchForm.barCode"
                  clearable
                  placeholder="搜索商品条形码"
                ></el-input>
                <el-input
                  class="search-manufacturer"
                  clearable
                  v-model="searchForm.saleOrderId"
                  placeholder="搜索销售单编号"
                ></el-input>
                <el-tooltip
                  effect="dark"
                  content="请选择销售日期"
                  placement="top"
                >
                  <el-date-picker
                    value-format="yyyy-MM-dd"
                    style="margin-left: 10px"
                    v-model="searchForm.saleTime"
                    clearable
                    type="date"
                    placeholder="请选择销售日期"
                  >
                  </el-date-picker>
                  ></el-tooltip
                >
                <el-button
                  class="search-btn"
                  type="primary"
                  icon="el-icon-search"
                  @click="search"
                  >搜索销售商品明细</el-button
                >
                <el-button
                  class="search-btn"
                  type="danger"
                  icon="el-icon-reload"
                  @click="resetSearch"
                  >重置搜索条件</el-button
                >
              </div>
            </el-form>
          </div>
          <div class="wrapper" style="padding-top: 10px">
            <div class="right">
              <el-button
                @click="showExcelImportModal"
                type="primary"
                icon="el-icon-upload2"
                >Excel导入销售单明细</el-button
              >
              <!-- <el-button @click="batchDelete" type="danger" plain
                >批量删除</el-button
              > -->
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-tabs
            style="padding: 0 30px"
            v-model="searchForm.group"
            @tab-click="handleClick"
          >
            <el-tab-pane
              v-for="(item, index) of groupList"
              :key="index"
              :label="item.k1"
              :name="item.k1"
            >
              <span slot="label"
                >{{ item.k1 }} <span class="badge">{{ item.v1 }}</span></span
              >
              <el-table
                v-loading="loading"
                ref="multipleTable"
                :border="true"
                :stripe="true"
                :data="tableData"
                style="width: 100%"
                size="mini"
                height="calc(100vh - 358px)"
                highlight-current-row
                @current-change="tableCurrentChange"
                :current-row-key="currentRowKey"
              >
                <!-- <el-table-column
                  style="padding-left: 10px"
                  fixed="left"
                  type="selection"
                  width="55"
                ></el-table-column> -->
                <!-- <el-table-column width="80" prop="id" label="编码">
                </el-table-column> -->
                <el-table-column
                  align="center"
                  width="130"
                  prop="statusName"
                  label="状态"
                >
                  <template 
                      v-if="scope.row.statusName !== ''" slot-scope="scope">
                    <el-tag
                      v-for="(item, index) of scope.row.statusName.split(',')"
                      :key="index"
                      style="
                        font-size: 12px;
                        border-radius: 10px;
                        padding: 0 8px;
                        cursor: pointer;
                      "
                      :type="index % 2 ? 'success' : 'error'"
                      size="mini"
                      effect="dark"
                      >{{ item }}</el-tag
                    >
                  </template>
                </el-table-column>

                <el-table-column width="120" prop="saleTime" label="销售日期">
                </el-table-column>
                <el-table-column width="160" prop="saleOrderId" label="销售单号">
                </el-table-column>
                <el-table-column
                  width="280"
                  prop="otherName"
                  label="门店商品信息"
                >
                  <template slot-scope="scope">
                    <p class="product-text">
                      <b>商品编码：</b>{{ scope.row.productId }}
                    </p>
                    <p class="product-text">
                      <b>商品名称：</b>{{ scope.row.name }}
                    </p>
                    <p class="product-text">
                      <b>生产厂家：</b>{{ scope.row.manufacturer }}
                    </p>
                    <p class="product-text">
                      <b>规格：</b>{{ scope.row.format }}
                    </p>
                    <p class="product-text">
                      <b>单位：</b>{{ scope.row.unit }}
                    </p>
                    <p class="product-text">
                      <b>剂型：</b>{{ scope.row.dosageForm }}
                    </p>
                    <p class="product-text">
                      <b>批准文号：</b>{{ scope.row.license }}
                    </p>
                    <p class="product-text">
                      <b>条码：</b>{{ scope.row.barCode }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column width="180" prop="batchNo" label="批号效期">
                  <template slot-scope="scope">
                    <p class="product-text">批号：{{ scope.row.batchNo }}</p>
                    <p class="product-text">
                      生产日期：{{ scope.row.madeTime }}
                    </p>
                    <p class="product-text">
                      有效期：{{ scope.row.availTime }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="amount" label="销售数量">
                </el-table-column>
                <el-table-column prop="price" label="单价"> </el-table-column>
                <el-table-column prop="money" label="金额"> </el-table-column>
                <!-- <el-table-column
                  width="150"
                  align="center"
                  prop="buyImgPath"
                  label="随货同行单照片"
                >
                  <template slot-scope="scope">
                    <el-image
                      v-if="scope.row.buyImgPath && scope.row.buyImgPath !== ''"
                      style="width: 100px; height: 100px"
                      :src="scope.row.buyImgPath.split(',')[0]"
                      :preview-src-list="scope.row.buyImgPath.split(',')"
                    >
                    </el-image>
                  </template>
                </el-table-column> -->
                <el-table-column fixed="right" label="操作区" width="140">
                  <template slot-scope="scope">
                    <el-button
                      style="color: #f56c6c; margin-left: 10px"
                      v-if="
                        !scope.row.mendianProductId ||
                        scope.row.mendianProductId === ''
                      "
                      @click.native.prevent="
                        openNewWindow('/chain/goodsList', { openExcel: true })
                      "
                      type="text"
                      size="small"
                    >
                      上传商品档案
                    </el-button>
                    <!-- <el-button
                      style="margin-left: 10px"
                      v-if="
                        scope.row.buyImgFlag == '0' ||
                        !scope.row.buyImgPath ||
                        scope.row.buyImgPath === ''
                      "
                      @click.native.prevent="showGoodsImgModal(scope.row)"
                      type="text"
                      size="small"
                    >
                      上传随货同行
                    </el-button> -->

                    <el-button
                      style="margin-left: 10px"
                      v-if="
                        !scope.row.liansuoProductId ||
                        scope.row.liansuoProductId === ''
                      "
                      @click.native.prevent="waitMatchList"
                      type="text"
                      size="small"
                    >
                      待匹配商品档案
                    </el-button>
                    <el-button
                      style="color: #f56c6c; margin-left: 10px"
                      @click.native.prevent="deleteRow(scope.row.id)"
                      type="text"
                      size="small"
                    >
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
          <div class="bottom-page-content">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNo"
              :page-sizes="[20, 30, 40, 50]"
              layout=" sizes,prev,pager, next,total"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <add-goods-modal @uploadSuccess="uploadSuccess" ref="addGoodsModal" />

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <add-goods-image-modal
      @preViewGoodsImg="preViewGoodsImg"
      @submitGoodsImg="submitGoodsImg"
      ref="addGoodsImageModal"
    />
  </div>
</template>

<script>
import { deleteAction, downFile, getAction, putAction } from "../../api/manage";
import AddGoodsModal from "./modules/AddGoodsModal.vue";
import AddGoodsImageModal from "./modules/AddGoodsImageModal.vue";
import { getUserInfo } from '../../assets/js/common/common';

export default {
  components: { AddGoodsModal, AddGoodsImageModal },
  data() {
    return {
      groupList: [],
      loading: false,
      totalCount: 0,
      pageNo: 1,
      pageSize: 20,
      searchForm: {
        searchVal: "",
        group: "待处理",
      },
      mapFlagOptions: [
        { value: "", label: "全部" },
        { value: "1", label: "已匹配" },
        { value: "0", label: "未匹配" },
      ],
      mapTypeOptions: [
        { value: "", label: "全部" },
        { value: "手动", label: "手动匹配" },
        { value: "自动", label: "自动匹配" },
      ],
      inventoryFlagOptions: [
        { value: "", label: "全部" },
        { value: "1", label: "有库存" },
        { value: "0", label: "无库存" },
      ],
      tableData: [], // 门店商品列表
      multipleSelection: [],
      currentRow: null,
      dialogImageUrl: "",
      dialogVisible: false,
      userInfo: {},
      currentRowKey: "id", // 假设每行数据都有一个唯一的 id 字段
    };
  },
  created() {
    let userInfo = getUserInfo();
    console.log("当前登录的用户信息为", userInfo)
    if(userInfo && userInfo !== '') {
      this.userInfo = userInfo;
    }
    let group = localStorage.getItem("saleOrderGroup");
    if (group && group !== "") {
      this.searchForm.group = group;
    }
    this.loadData(1);
  },
  methods: {
    showGoodsImgModal(scopeRow) {
      this.$refs.addGoodsImageModal.show(scopeRow);
    },
    waitMatchList() {
      localStorage.setItem("group", '未匹配');
      this.openNewWindow("/chain/goodsList");
    },
    submitGoodsImg(url) {
      console.log("当前上传的图片地址为123", url);
      if (url && url !== "") {
        this.loadData(1);
      } else {
        this.form.picUrl = "";
      }
      this.$forceUpdate();
    },
    preViewGoodsImg(imgUrl) {
      this.dialogImageUrl = imgUrl;
      this.dialogVisible = true;
    },
    openNewWindow(path, query) {
      const routeData = this.$router.resolve({ path: path, query: query });
      console.log("routeData+++++++++++", routeData);
      window.open(routeData.href, "_blank");
    },
    // addGoodsRoute() {
    //   this.$router.push({ path: "/chain/goodsDetail" });
    // },
    auditMatch(item) {
      this.$confirm("确定审批关联？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 关联商品
        let url = "/liansuo/mendian/mendianSaleOrderItem/edit";
        let params = {
          id: item.id,
          status: "1",
        };
        putAction(url, params).then((res) => {
          console.log("关联商品返回的数据为", res);
          if (res.code === 200 && res.success) {
            this.$message({
              message: "审批成功！",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            this.loadData(1);
          }
        });
        console.log("确认关联的数据为", params);
      });
    },
    handleClick(val) {
      let group = val.name;
      this.searchForm.group = group;
      localStorage.setItem("saleOrderGroup", group);
      console.log("当前选择的筛选状态", group);
      this.loadData(1);
    },
    exportExcel({ fileName = "门店商品导出表" }) {
      let url = "/liansuo/productMendian/mendian/exportXls";
      downFile(url, this.searchForm).then((data) => {
        console.log("导出数据", data);
        if (!data) {
          this.$message.warning("文件下载失败");
          return;
        }
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(new Blob([data]), fileName + ".xls");
        } else {
          let url = window.URL.createObjectURL(new Blob([data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName + ".xls");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      });
    },
    batchDelete() {
      let seletecdRow = this.multipleSelection;
      let ids = [];
      seletecdRow.forEach((item) => {
        ids.push(item.id);
      });
      if (ids.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择要删除的数据！",
        });
        return;
      }
      this.$confirm("确定要删除选中的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = "/liansuo/mendian/mendianSaleOrderItem/deleteBatch";
          let idsStr = ids.toString();
          deleteAction(url, {
            ids: idsStr,
          }).then((res) => {
            console.log("批量删除商品列表数据为", ids);
            if (res.code === 200 && res.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.loadData(1);
            } else {
              this.$message({
                type: "error",
                message: res.message,
              });
            }
          });
          return;
        })
        .catch(() => {
          // this.$message({
          //   type: "error",
          //   message: "批量删除操作失败",
          // });
        });
    },
    tableCurrentChange(val) {
      this.currentRow = val;
    },
    // 点击关联匹配
    showMatchProductsModal(item) {
      this.$refs.matchProductsModal.showModal(item);
    },
    // 修改本地商品库数据
    editGoodsData(item) {
      // this.$router.push({
      //   path: "/chain/goodsDetail",
      //   query: { id: item.id, opreateType: "1" },
      // });
      this.openNewWindow("/chain/goodsDetail", {
        id: item.id,
        opreateType: "1",
      });
    },
    // 确认匹配
    confirmMatch() {},
    toggleSelection(rows) {
      console.log("点击选中的复选框事件", rows);
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      this.searchForm.group = "待处理";
      let group = localStorage.getItem("saleOrderGroup");
      console.log("group++++++++", group);
      if (group && group !== "") {
        localStorage.removeItem("saleOrderGroup");
      }
      this.loadData(1);
    },
    resetSearch() {
      this.searchForm = {
        searchVal: "",
        group: "待处理",
      };
      this.group = "1";
      this.loadData(1);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      let pageNo = this.pageNo;
      this.loadData(pageNo);
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.loadData(val);
    },
    // 上传成功
    uploadSuccess() {
      this.loadData(1);
    },
    loadData(page) {
      let _this = this;
      this.loading = true;
      let url = "/liansuo/mendian/mendianSaleOrderItem/list";
      let pageSize = this.pageSize;
      let data = {
        ...this.searchForm,
        pageNo: page,
        pageSize: pageSize,
      };
      getAction(url, data).then((res) => {
        _this.loading = false;
        if (res.code === 200 && res.success) {
          _this.groupList = res.result.groupList;
          _this.tableData = res.result.list.records;
          console.log("商品列表数据为", _this.tableData);
          _this.totalCount = res.result.list.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    showExcelImportModal() {
      this.$refs.addGoodsModal.showExcelImportModal();
    },
    deleteRow(id) {
      this.$confirm("该操作会删除门店该商品数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = { id: id };
          let url = "/liansuo/mendian/mendianSaleOrderItem/delete";
          deleteAction(url, params).then((res) => {
            if (res.code === 200 && res.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.loadData(1);
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "删除操作失败",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 18px;
}
* {
  font-size: 14px;
}
.code-matching {
  height: 100%;
}

.code-matching-title {
  display: flex;
  height: 60px;
  background-color: #24263d;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  .back-account-center {
    position: absolute;
    left: 20px;
  }
}
.dropdown-btn {
  color: #409eff !important;
  background: #ecf5ff !important;
}
.code-matching-content {
  padding: 10px 10px;
  background-color: #ecedf2;
  height: calc(100% - 60px);
  .search-content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    padding: 20px;
    .wrapper {
      width: 100%;
      .left {
        width: 100%;
        .el-input {
          width: 180px;
          min-width: 150px;
          margin-bottom: 10px;
          &.search-manufacturer {
            width: 200px;
          }
        }
        ::v-deep .el-input__inner {
          border-radius: 5px !important;
        }
        .el-select {
          min-width: 100px;
          width: 150px;
          margin-left: 10px;
          margin-bottom: 10px;
        }
        .search-input {
          padding: 0 10px;
          border-radius: 5px;
        }
        .search-btn {
          margin-left: 10px;
          margin-bottom: 10px;
        }
      }
      .right {
        .el-button {
          margin-left: 10px;
          margin-bottom: 10px;
        }

        .dropdown-btn {
          color: #409eff !important;
          background: #ecf5ff !important;
          border-color: #409eff !important;
          &:hover {
            background-color: #409eff !important;
            color: #ffffff !important;
          }
        }
      }
    }
  }
  ::v-deep .el-table th.el-table__cell {
    overflow: hidden;
    user-select: none;
    background-color: #f6f7fa !important;
  }
}
.table-content {
  background-color: #ffffff;
  .bottom-page-content {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    .opreate-btn {
      display: flex;
      div {
        width: 30px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: justify;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    ::v-deep .el-pagination__total {
      line-height: 28px !important;
    }
  }
}
::v-deep .el-table-column--selection {
  .el-checkbox {
    padding-left: 10px;
  }
}
/* 全局样式文件，例如 main.css */
::-webkit-scrollbar {
  width: 6px; /* 滚动条宽度 */
}
::-webkit-scrollbar-track {
  background: #f1f1f1; /* 滚动条轨道背景色 */
}

::-webkit-scrollbar-thumb {
  background: #888; /* 滚动条滑块背景色 */
  border-radius: 3px; /* 滑块圆角 */
}

.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555; /* 滑块悬停时背景色 */
}
// ::v-deep .el-table__body-wrapper, ::v-deep .el-scrollbar__wrap {
//   &::-webkit-scrollbar {
//     width: 6px;
//     height: 6px;
//   }
// }
::v-deep .el-input {
  .el-input__clear {
    padding-right: 15px !important;
  }
}
.badge {
  background-color: red;
  color: white;
  border-radius: 50%; /* 使其成为圆形 */
  padding: 2px 5px;
  margin-left: 5px; /* 距离标签的距离 */
  position: relative;
  top: -10px; /* 调整到合适的位置 */
  font-size: 12px;
}
</style>