<template>
  <div class="side-bar">
    <div class="order center-box">
      <h3>
        我的订单
      </h3>
      <div>
        <p :class="{ active: selectIndex === 0 }">
          <router-link :to="{ name: 'orderList', query: { state: 0 } }">
            全部订单
          </router-link>
        </p>
        <p :class="{ active: selectIndex === 1 }">
          <router-link :to="{ name: 'orderList', query: { state: 1 } }">
            待配货订单
          </router-link>
        </p>
        <p :class="{ active: selectIndex === 2 }">
          <router-link :to="{ name: 'orderList', query: { state: 2 } }">
            待处理订单
          </router-link>
        </p>
        <p :class="{ active: selectIndex === 3 }">
          <router-link :to="{ name: 'orderList', query: { state: 3 } }">
            已完成订单
          </router-link>
        </p>
        <p :class="{ active: selectIndex === 4 }">
          <router-link :to="{ name: 'orderList', query: { state: 4 } }">
            已取消订单
          </router-link>
        </p>
        <p :class="{ active: selectIndex === 14 }">
          <router-link :to="{ name: 'refundList', query: { state: 13 } }">
            售后订单
          </router-link>
        </p>
      </div>
    </div>
    <div class="center center-box">
      <h3>
        个人中心
      </h3>
      <div>
        <p :class="{ active: selectIndex === 10 }">
          <router-link to="/changeAccount"> 切换账户 </router-link>
        </p>
        <p :class="{ active: selectIndex === 5 }">
          <router-link to="/myAccount"> 我的账户 </router-link>
        </p>
        <p v-if="false" :class="{ active: selectIndex === 6 }">
          <router-link to="/addressList"> 地址管理 </router-link>
        </p>
        <p :class="{ active: selectIndex === 7 }">
          <router-link to="/wantToBuyProduct">我的求购</router-link>
        </p>
        <p :class="{ active: selectIndex === 8 }">
          <router-link to="/collect">我的收藏</router-link>
        </p>
        <p :class="{ active: selectIndex === 9 }">
          <router-link to="/giftList">我的礼品</router-link>
        </p>
        <p :class="{ active: selectIndex === 11 }">
          <router-link to="/messageList">我的消息</router-link>
        </p>
        <p :class="{ active: selectIndex === 12 }">
          <router-link to="/feedbackList">我的反馈</router-link>
        </p>
        <p v-if="companyId === 'hbsb'" :class="{ active: selectIndex === 15 }">
          <router-link to="/chain" target="_blank">门店商品对码</router-link>
        </p>
        <p v-if="companyId === 'hbsb'" :class="{ active: selectIndex === 16 }">
          <router-link to="/buyOrderImport" target="_blank">门店采购单导入</router-link>
        </p>
        <p v-if="companyId === 'hbsb'" :class="{ active: selectIndex === 17 }">
          <router-link to="/saleOrderImport" target="_blank">门店销售单导入</router-link>
        </p>
      </div>
    </div>
    <div v-if="companyNoticeList.length > 0" class="notice center-box">
      <h3>
        通知公告
      </h3>
      <div>
        <p v-for="(item, index) of companyNoticeList" :class="{
          active: $route.name == 'notice' && item.id == $route.query.id,
        }" :key="'notice' + index">
          <router-link :to="{
            name: 'notice',
            query: {
              id: item.id,
            },
          }">
            {{ item.name }}
          </router-link>
        </p>
      </div>
    </div>
    <div v-if="companyCredentialsList.length > 0" class="credentials center-box">
      <h3>
        平台资质
      </h3>
      <div>
        <p v-for="(item, index) of companyCredentialsList" :class="{
          active: $route.name == 'credentials' && item.id == $route.query.credentialsId,
        }" :key="'credentials' + index">
          <router-link :to="{
            name: 'credentials',
            query: {
              credentialsId: item.id,
            },
          }">
            {{ item.credentialsName }}
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getAction } from "../../api/manage";
export default {
  data() {
    return {
      routerPath: "",
      companyId: "",
      selectIndex: -1,
      noticeNav: [],
      credentialsList: [], // 平台资质列表
      salesLoginStatus: false,
    };
  },
  created() {
    let userInfo = this.userInfo;
    if(userInfo && userInfo.companyId) {
      this.companyId = userInfo.companyId;
    }
    let routerPath = this.$route.path;
    this.routerPath = routerPath;
    let routerName = this.$route.name;
    let query = this.$route.query;
    this.selectLeft(routerName, query);
  },
  computed: {
    ...mapGetters(["salemanToken","userInfo","companyNoticeList","companyCredentialsList"]),
  },
  methods: {
    getNoticeList() {
      let _this = this;
      let url = "/notice/notice/list";
      getAction(url).then((res) => {
        if ((res.code === 200) & res.success) {
          let resData = res.result;
          _this.noticeNav = resData.records;
        }
      });
    },
    // 获取后台上传的公司资质
    getCompanyCredentials() {
      let _this = this
      let url = '/company/companyCredentials/list'
      getAction(url).then(res => {
        if (res.code === 200 && res.success) {
          let credentialsList = res.result.records
          this.credentialsList = credentialsList
          _this.$store.commit('COMPANY_CREDENTIALS_LIST', credentialsList)
        }
      })
    },
    selectLeft(routerName, query) {
      this.selectIndex = -1;
      if (routerName === "orderList") {
        let state = parseInt(query.state);
        this.selectIndex = state;
      } else if (routerName === "addressList") {
        this.selectIndex = 6;
      } else if (routerName === "myAccount") {
        this.selectIndex = 5;
      } else if (routerName === "wantToBuyProduct") {
        this.selectIndex = 7;
      } else if (routerName === "collect") {
        this.selectIndex = 8;
      } else if (routerName === "giftList") {
        this.selectIndex = 9;
      } else if (routerName === "changeAccount") {
        this.selectIndex = 10;
      } else if (routerName === "messageList") {
        this.selectIndex = 11;
      } else if (routerName === "feedbackList") {
        this.selectIndex = 12;
      } else if (routerName === "refundList") {
        this.selectIndex = 13;
      }
    },
  },
  watch: {
    $route(to, form) {
      let routerName = to.name;
      let query = to.query;
      this.selectLeft(routerName, query);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/order/left";
</style>