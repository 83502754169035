<template>
  <el-dialog
    title="添加随货同行单图片"
    :visible.sync="uploadDialogVisible"
    width="665px"
    :before-close="handleClose"
  >
    <div class="upload-content" v-loading="loading">
      <div class="upload-btn">
        <el-upload
          action="/web/file/img/uploadImg"
          :data="otherData"
          accept="image/jpg,image/png,image/jpeg"
          list-type="picture-card"
          :limit="5"
          :file-list="goodsImgList"
          :on-success="uploadSuccessGoodsImg"
          :on-preview="handlePreviewGoodsImg"
          :on-remove="handleRemove"
        >
          <div class="upload-btn-text">
            <i class="el-icon-plus"></i>
            <span>上传图片</span>
          </div>
        </el-upload>
      </div>

      <div class="el-upload__tip" slot="tip">
        请上传常见的图片格式：jpeg，jpg，png，gif
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="uploadDialogVisible = false">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submitUpload">{{
        loading ? "正在上传图片" : "确认"
      }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { putAction } from '../../../api/manage';
export default {
  data() {
    return {
      loading: false,
      uploadDialogVisible: false,
      goodsImgList: [],
      otherData: {},
      goodsItemId: ""
    };
  },
  created() {
    let token = this.$base64.decode(this.token);
    this.otherData = {
      type: 14,
      "Shopping-Access-Token": token,
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  methods: {
    show(scopeRow) {
      this.goodsItemId = scopeRow.id;
      let goodsImgUrl = scopeRow.buy_img_path;
      if (goodsImgUrl && goodsImgUrl !== "") {
        let goodsImgArr = goodsImgUrl.split(",");
        this.goodsImgList = goodsImgArr.map((item) => {
          return {
            name: item,
            url: item,
          };
        });
        this.goodsImgList = this.goodsImgList.filter((item) => {
          return item.url !== "";
        });
      } else {
        this.goodsImgList = [];
      }
      this.uploadDialogVisible = true;
    },
    // 移除凭证照片操作
    handleRemove(file, fileList) {
      console.log("当前移除的售后凭证file", file);
      console.log("当前移除的售后凭证fileList", fileList);
      this.goodsImgList = fileList;
    },
    // 上传售后图片成功
    uploadSuccessGoodsImg(response, file, fileList) {
      console.log("上传图片成功的response", response);
      console.log("上传图片成功的fileList", fileList);
      if (response.code === 200 && response.success) {
        this.goodsImgList = fileList;
        let imgUrl = response.message;
        file.url = imgUrl;
      } else {
        this.$message.error("商品图片上传失败！");
      }
    },
    // 提交上传
    submitUpload() {
      let goodsImgList = this.goodsImgList;
      console.log("当前上传的图片列表", goodsImgList)
      if(goodsImgList.length > 0) {
        let picUrl = goodsImgList.map(item => item.url).toString();
        console.log("该用户上传的图片地址为", picUrl)
        // 上传采购明细随货同行照片
        let url = "/liansuo/mendian/mendianBuyOrderItem/edit";
        let params = {
          id: this.goodsItemId,
          buyImgPath: picUrl
        };
        putAction(url, params).then(res => {
          if (res.success) {
            this.$message.success("上传成功！");
            this.$emit('submitGoodsImg', picUrl);
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.$emit('submitGoodsImg', "");
      }
      this.handleClose()
    },
    // 预览图片
    handlePreviewGoodsImg(file) {
      this.$emit('preViewGoodsImg', file.url);
    },
    handleClose() {
      this.uploadDialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-btn-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    display: inline-block;
    line-height: 1;
    margin-top: 5px;
  }
}
::v-deep .el-upload-list__item-actions {
  span {
    i {
      font-size: 18px;
    }
  }
}
</style>